// import React from "react";
import "./index.css";
import { AppRoutes } from "./navigation/Routes";
import { createRoot } from "react-dom/client";
import "./i18n";

const container = document.getElementById("root");
if (container !== null) {
  const root = createRoot(container);
  root.render(
    // <React.StrictMode>
    <AppRoutes />
    // </React.StrictMode>
  );
}
