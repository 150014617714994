import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";

export default function MissionSection() {
  const { t } = useTranslation();
  return (
    <Container
      fluid
      className="pt-5 px-0 wow fadeInUp d-flex align-items-center "
      style={{ minHeight: "100vh" }}
      data-wow-delay="0.1s"
    >
      <Row
        className="g-0 reel justify-content-center"
        style={{
          paddingTop: "55px",
          paddingBottom: "55px",
        }}
      >
        <Col className="d-flex justify-content-center" md={4}>
          <video
            width="100%"
            height="auto"
            src="../assets/video/reel.mp4"
            title="Um Khater Cloud Kitchen"
            controls
            loop
          ></video>
          {/* <img src="../assets/img/7.jpg" width="100%" /> */}
        </Col>

        <Col lg={5} className="bg-dark d-flex align-items-center our-mission">
          <div
            style={{ color: "white" }}
            className="p-5 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <h3 className="section-title ff-secondary text-start text-primary fw-normal">
              {t("our_mission")}
            </h3>
            <p className="mb-4">{t("our_mission_desc")}</p>
            <br />
            <h3 className="section-title ff-secondary text-start text-primary fw-normal">
              {t("our_vision")}
            </h3>
            <p className="mb-4">{t("our_vision_desc")}</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
