import FindUsSection from "../components/HomeComponents/FindUsComponent";
import MenuFirstSection from "../components/MenuComponents/FirstSectionComponent";
import MenuPageSection from "../components/MenuComponents/FoodMenuComponent";
import { Helmet } from "react-helmet-async";

function MenuPage() {
  return (
    <>
      <Helmet>
        <title>Um Khater | Menu</title>
      </Helmet>
      <MenuFirstSection />
      <MenuPageSection />
      <FindUsSection />
    </>
  );
}

export default MenuPage;
