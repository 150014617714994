import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // Import CSS
import { useState } from "react";
import { useTranslation } from "react-i18next";

const main_dishes = [
  {
    title: "molokhia",
    descriptionKey: "molokhia_desc",
    image_url: "../assets/img/menu/17.png",
  },
  {
    title: "shish_barak",
    descriptionKey: "shish_barak_desc",
    image_url: "../assets/img/menu/13.png",
  },
  {
    title: "kibbeh_labanieh",
    descriptionKey: "kibbeh_labanieh_desc",
    image_url: "../assets/img/menu/3.png",
  },
  {
    title: "shakriyeh",
    descriptionKey: "shakriyeh_desc",
    image_url: "../assets/img/menu/7.png",
  },
  {
    title: "chicken_rice",
    descriptionKey: "chicken_rice_desc",
    image_url: "../assets/img/menu/4.png",
  },
  {
    title: "freekeh",
    descriptionKey: "freekeh_desc",
    image_url: "../assets/img/menu/16.png",
  },
  {
    title: "maklouba",
    descriptionKey: "maklouba_desc",
    image_url: "../assets/img/menu/19.png",
  },
  {
    title: "white_beans",
    descriptionKey: "white_beans_desc",
    image_url: "../assets/img/menu/15.png",
  },
  {
    title: "rice_with_beans",
    descriptionKey: "rice_with_beans_desc",
    image_url: "../assets/img/menu/9.png",
  },
  {
    title: "rice_with_peas",
    descriptionKey: "rice_with_peas_desc",
    image_url: "../assets/img/menu/1.png",
  },
  {
    title: "dawood_basha",
    descriptionKey: "dawood_basha_desc",
    image_url: "../assets/img/menu/21.png",
  },
  {
    title: "kabab_hindi",
    descriptionKey: "kabab_hindi_desc",
    image_url: "../assets/img/menu/14.png",
  },
  {
    title: "mahashi",
    descriptionKey: "mahashi_desc",
    image_url: "../assets/img/menu/11.png",
  },
  {
    title: "bamieh_bi_lahmeh",
    descriptionKey: "bamieh_bi_lahmeh_desc",
    image_url: "../assets/img/menu/18.png",
  },
  {
    title: "kousa_bel_laban",
    descriptionKey: "kousa_bel_laban_desc",
    image_url: "../assets/img/menu/8.png",
  },
  {
    title: "yabrak",
    descriptionKey: "yabrak_desc",
    image_url: "../assets/img/menu/12.png",
  },
];

const side_dishes = [
  {
    title: "yalanji",
    descriptionKey: "yalanji_desc",
    image_url: "../assets/img/menu/10.png",
  },
  {
    title: "grilled_kibbeh",
    descriptionKey: "grilled_kibbeh_desc",
    image_url: "../assets/img/logo.png",
  },
  {
    title: "fried_kibbeh",
    descriptionKey: "fried_kibbeh_desc",
    image_url: "../assets/img/logo.png",
  },
];

const salads = [
  {
    title: "fattoush",
    descriptionKey: "fattoush_desc",
    image_url: "../assets/img/logo.png",
  },
];

const soups = [
  {
    title: "lentil_soup",
    descriptionKey: "lentil_soup_desc",
    image_url: "../assets/img/menu/5.png",
  },
  {
    title: "mushroom_soup",
    descriptionKey: "mushroom_soup_desc",
    image_url: "../assets/img/menu/6.png",
  },
  {
    title: "chicken_soup",
    descriptionKey: "chicken_soup_desc",
    image_url: "../assets/img/logo.png",
  },
];

export default function MenuPageSection() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const openLightbox = (imageUrl: any) => {
    setCurrentImage(imageUrl);
    setIsOpen(true);
  };

  return (
    <Container id="menu" fluid className="container-xxl py-5">
      <Container>
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h5 className="section-title ff-secondary text-center text-primary fw-normal">
            {t("food_menu")}
          </h5>
        </div>

        <div
          className="tab-className text-center wow fadeInUp mt-5"
          data-wow-delay="0.1s"
        >
          <ul className="nav nav-pills d-flex border-bottom mb-5 scrollable-nav">
            <li className="nav-item">
              <a
                className="d-flex align-items-center text-start mx-3 pb-3 active"
                data-bs-toggle="pill"
                href="#tab-1"
              >
                <img
                  src="../assets/img/icons/burger.svg"
                  width={"35"}
                  alt="logo"
                />
                {/* <i className="fa fa-hamburger fa-2x text-primary"></i> */}
                <div className="ps-1 categories">
                  <small className="text-body">{t("main")}</small>
                  <h6 className="mt-n1 mb-0">{t("courses")}</h6>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="d-flex align-items-center text-start mx-3 me-0 pb-3"
                data-bs-toggle="pill"
                href="#tab-2"
              >
                <img
                  src="../assets/img/icons/spoon.svg"
                  width={"35"}
                  alt="logo"
                />
                {/* <i className="fa fa-utensils fa-2x text-primary"></i> */}
                <div className="ps-1 categories">
                  <small className="text-body">{t("side")}</small>
                  <h6 className="mt-n1 mb-0">{t("dishes")}</h6>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="d-flex align-items-center text-start mx-3 me-0 pb-3"
                data-bs-toggle="pill"
                href="#tab-3"
              >
                <img
                  src="../assets/img/icons/soup.svg"
                  width={"35"}
                  alt="logo"
                />
                {/* <i className="fa-solid fa-bowl-hot text-primary"></i> */}
                <div className="ps-1 categories">
                  <small className="text-body">{t("soups")} </small>
                  <h6 className="mt-n1 mb-0">{t("um_mukhtar")} </h6>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="d-flex align-items-center text-start mx-3 me-0 pb-3"
                data-bs-toggle="pill"
                href="#tab-4"
              >
                <img
                  src="../assets/img/icons/salad.svg"
                  width={"35"}
                  alt="logo"
                />
                {/* <i className="fa fa-utensils fa-2x text-primary"></i> */}
                <div className="ps-1 categories">
                  <small className="text-body">{t("salads")} </small>
                  <h6 className="mt-n1 mb-0">{t("um_mukhtar")} </h6>
                </div>
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div id="tab-1" className="tab-pane fade show p-0 active">
              <Row className="g-4">
                {main_dishes.map((item, index) => (
                  <Col key={index} lg={6}>
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        className="flex-shrink-0 img-fluid rounded"
                        src={item.image_url}
                        alt={t(item.title)}
                        style={{ width: "100px", cursor: "pointer" }}
                        onClick={() => openLightbox(item.image_url)}
                      />
                      <div className="w-100 d-flex flex-column text-start ps-4 menu-food-ar">
                        <h5 className="d-flex justify-content-between border-bottom pb-2">
                          <span>{t(item.title)}</span>
                        </h5>
                        <small className="fst-italic food-desc">
                          {t(item.descriptionKey)}
                        </small>
                      </div>
                    </div>
                  </Col>
                ))}
                {isOpen && (
                  <Lightbox
                    mainSrc={currentImage}
                    onCloseRequest={() => setIsOpen(false)}
                  />
                )}
              </Row>
            </div>

            <div id="tab-2" className="tab-pane fade show p-0 ">
              <Row className="g-4">
                {side_dishes.map((item, index) => (
                  <Col key={index} lg={6}>
                    <div className="d-flex align-items-center">
                      <img
                        className="flex-shrink-0 img-fluid rounded"
                        src={item.image_url}
                        alt={t(item.title)}
                        style={{ width: "100px", cursor: "pointer" }}
                        onClick={() => openLightbox(item.image_url)}
                      />
                      <div className="w-100 d-flex flex-column text-start ps-4 menu-food-ar">
                        <h5 className="d-flex justify-content-between border-bottom pb-2">
                          <span>{t(item.title)}</span>
                        </h5>
                        <small className="fst-italic food-desc">
                          {t(item.descriptionKey)}
                        </small>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>

            <div id="tab-3" className="tab-pane fade show p-0 ">
              <Row className="g-4">
                {soups.map((item, index) => (
                  <Col key={index} lg={6}>
                    <div className="d-flex align-items-center">
                      <img
                        className="flex-shrink-0 img-fluid rounded"
                        src={item.image_url}
                        alt={t(item.title)}
                        style={{ width: "100px", cursor: "pointer" }}
                        onClick={() => openLightbox(item.image_url)}
                      />
                      <div className="w-100 d-flex flex-column text-start ps-4 menu-food-ar">
                        <h5 className="d-flex justify-content-between border-bottom pb-2">
                          <span>{t(item.title)}</span>
                        </h5>
                        <small className="fst-italic food-desc">
                          {t(item.descriptionKey)}
                        </small>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>

            <div id="tab-4" className="tab-pane fade show p-0 ">
              <Row className="g-4">
                {salads.map((item, index) => (
                  <Col key={index} lg={6}>
                    <div className="d-flex align-items-center">
                      <img
                        className="flex-shrink-0 img-fluid rounded"
                        src={item.image_url}
                        alt={t(item.title)}
                        style={{ width: "100px", cursor: "pointer" }}
                        onClick={() => openLightbox(item.image_url)}
                      />
                      <div className="w-100 d-flex flex-column text-start ps-4 menu-food-ar">
                        <h5 className="d-flex justify-content-between border-bottom pb-2">
                          <span>{t(item.title)}</span>
                        </h5>
                        <small className="fst-italic food-desc">
                          {t(item.descriptionKey)}
                        </small>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </Container>
  );
}
