import FirstSection from "../components/NotFoundComponents/FirstSectionComponent";
import SecondSection from "../components/NotFoundComponents/SecondSectionComponent";
import { Helmet } from "react-helmet-async";

function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>Um Khater | 404 Not Found</title>
      </Helmet>
      <FirstSection />
      <SecondSection />
    </>
  );
}

export default NotFoundPage;
