import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";

export default function WelcomeSection() {
  const { t } = useTranslation();

  return (
    <Container id="home" fluid className=" py-5 bg-dark hero-header ">
      <Container className="my-5 py-5">
        <Row className="align-items-center g-5">
          <Col className="text-center text-lg-start welcome" lg={6}>
            <h1 className="display-3 text-white animated slideInLeft">
              {t("enjoy")}
            </h1>
            <p className="text-white animated slideInLeft mb-4 pb-2">
              {t("about_us_1")}
            </p>
            <a
              href="/menu"
              style={{ borderRadius: "10px" }}
              className="btn btn-primary py-sm-3 px-sm-5 animated slideInLeft"
            >
              {t("our_menu")}
            </a>
          </Col>
          <Col className="text-center text-lg-end overflow-hidden" lg={6}>
            <img className="img-fluid" src="../assets/img/food/1.png" alt="" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
