import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";

export default function MenuFirstSection() {
  const { t } = useTranslation();
  return (
    <Container fluid className=" py-5 bg-dark hero-header mb-5">
      <Container className="text-center my-5 pt-5 pb-4">
        <h1 className="display-3 text-white mb-3 animated slideInDown">
          {t("menu")}
        </h1>
      </Container>
    </Container>
  );
}
