import { useState, useEffect } from "react";

export const Counter = (target: any) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const increment = target / 100;
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount < target) {
          return prevCount + increment;
        }
        clearInterval(interval);
        return target;
      });
    }, 20);

    return () => clearInterval(interval);
  }, [target]);

  return Math.round(count);
};

export default Counter;
