import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";

const apps = [
  {
    link: "https://deliveroo.ae/menu/dubai/al-quoz-3/um-khater-kitchen?utm_campaign=organic&utm_medium=referrer&utm_source=menu_share",
    image: "deliveroo.png",
  },
  {
    link: "https://www.talabat.com/uae",
    image: "talabat.png",
  },
  {
    link: "https://www.careem.com/",
    image: "careem.png",
  },

  {
    link: "https://getcari.com/",
    image: "cari.png",
  },
  {
    link: "https://www.resthero.io/",
    image: "rest-hero.png",
  },
];

export default function FindUsSection() {
  const { t } = useTranslation();
  return (
    <Container fluid className="find-us  pt-5 pb-3">
      <Container>
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h5 className="section-title ff-secondary text-center text-primary fw-normal">
            {t("order_online")}
          </h5>
          {/* <h1 className="mb-5">Find Us On</h1> */}
        </div>
        <Row className="g-4 d-flex align-items-center justify-content-center">
          {apps.map((item, index) => (
            <Col
              key={index}
              lg={2}
              className="col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="app text-center overflow-hidden">
                <a target="_blank" rel="noopener noreferrer" href={item.link}>
                  <img
                    className="img-fluid"
                    src={`../assets/img/${item.image}`}
                    alt=""
                  />
                </a>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}
