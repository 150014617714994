import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {
  faWhatsapp,
  faInstagram,
  faSnapchat,
  faFacebookF,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";

export default function FooterSection() {
  const { t } = useTranslation();
  return (
    <Container
      fluid
      className="bg-dark text-light footer pt-5 mt-0 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <Container id="contact" className="py-5">
        <Row className="g-5">
          <Col lg={3} className="col-md-6">
            <div className="d-flex justify-content-center">
              <img src="../assets/img/logo.png" alt="" />
            </div>
          </Col>
          <Col style={{ textAlign: "center" }} lg={6} className="col-md-6">
            <p className="mb-2">
              {t("connect_1")}
              <br />
              <br />
              {t("connect_2")}
            </p>
          </Col>
          <Col lg={3} className="col-md-6 contact-section">
            <h4 className="section-title ff-secondary text-center text-primary fw-normal mb-4">
              {t("contact")}
            </h4>

            <p className="mb-2">
              <i className="fa fa-phone-alt me-3 icon"></i>
              <a style={{ color: "white" }} href="tel:+971556533205">
                +971 55 653 3205
              </a>
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3 icon"></i>
              <a style={{ color: "white" }} href="mailto:contact@umkhater.com">
                online@umkhater.com
              </a>
            </p>
            <div className="d-flex pt-2 justify-content-center">
              <a
                className="btn btn-outline-light btn-social"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/um.khater.uae?igsh=M2Z6bXV3M2VkeGFp"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/profile.php?id=61556265674494&mibextid=ZbWKwL"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/971556533205"
              >
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                target="_blank"
                rel="noopener noreferrer"
                href="https://t.snapchat.com/myJ6kgkm"
              >
                <FontAwesomeIcon icon={faSnapchat} />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.tiktok.com/@um_khater_uae"
              >
                <FontAwesomeIcon icon={faTiktok} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <div className="copyright">
          <Row className=" justify-content-center d-flex">
            <div className="text-center">
              &copy; {t("all_right_reserved")} <br></br>
              {t("designed_by")}
              <a
                style={{ color: "#c38957" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.zoukhrof.com"
              >
                {" "}
                {t("zoukhrof")}
              </a>
            </div>
          </Row>
        </div>
      </Container>
    </Container>
  );
}
