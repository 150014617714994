import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Counter from "../../utils/counter";
import { useTranslation } from "react-i18next";

export default function AboutSection() {
  const { t } = useTranslation();

  return (
    <Container id="about" fluid className="py-0 about-us">
      <Container>
        <Row className="g-5 align-items-center">
          <Col lg={6} className="p-0 d-flex justify-content-center">
            <img
              style={{ width: "80%" }}
              className="img-fluid"
              alt=""
              src="../assets/img/menu/12.png"
            />
          </Col>
          <Col lg={6}>
            <h5 className="section-title ff-secondary text-start text-primary fw-normal">
              {t("about_us")}
            </h5>
            <h1 className="mb-4"> {t("welcome")}</h1>
            <p className="mb-4">{t("about_us_1")}</p>
            <p className="mb-4">{t("about_us_2")}</p>

            <Row className="g-4 mb-4 counters">
              <Col sm={6}>
                <div className="d-flex align-items-center border-start border-5 border-primary px-3 count">
                  <h1 className="flex-shrink-0 display-5 text-primary mb-0">
                    +{Counter(13)}
                  </h1>
                  <div className="ps-4">
                    <p className="mb-0">{t("years_of")}</p>
                    <h6 className="text-uppercase mb-0">{t("experience")}</h6>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="d-flex align-items-center border-start border-5 border-primary px-3 count">
                  <h1 className="flex-shrink-0 display-5 text-primary mb-0">
                    +{Counter(10)}
                  </h1>
                  <div className="ps-4">
                    <p className="mb-0">{t("popular")}</p>
                    <h6 className="text-uppercase mb-0">
                      {t("different_dish")}
                    </h6>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
