import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Nav } from "react-bootstrap";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  // Determine the switcher label and target language based on the current language
  const switchToLang = i18n.language.startsWith("ar") ? "en" : "ar";
  const switcherLabel = i18n.language.startsWith("ar") ? "English" : "Arabic";

  useEffect(() => {
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
  }, [i18n.language]); // This effect depends on the current language

  // Function to change language
  const changeLanguage = () => {
    i18n.changeLanguage(switchToLang);
  };

  return <Nav.Link onClick={changeLanguage}>{switcherLabel}</Nav.Link>;
}

export default LanguageSwitcher;
