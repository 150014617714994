import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";

export default function WhyUsSection() {
  const { t } = useTranslation();
  return (
    <Container id="whyus" fluid className="container-xxl py-5">
      <Container>
        <Row className="g-4 d-flex justify-content-center">
          <h5 className="ff-secondary text-start text-primary fw-normal why-header">
            {t("why_um_khater")}
          </h5>

          <Col lg={4} className="col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <h5>{t("authenticity")}</h5>
                <p>{t("authenticity_desc")}</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <h5>{t("quality")}</h5>
                <p>{t("quality_desc")}</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <h5>{t("convenience")}</h5>
                <p>{t("convenience_desc")}</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <h5>{t("variety")}</h5>
                <p>{t("variety_desc")}</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <h5>{t("full_experience")}</h5>
                <p>{t("full_experience_desc")}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
