import { Box } from "@mui/material";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Outlet } from "react-router-dom";
import FooterSection from "../../components/FooterComponent";
import useScrollToSection from "../../utils/ScrollToSection";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../utils/LanguageSwitcher";

function ResponsiveDrawer() {
  const scrollToSection = useScrollToSection();
  const { t } = useTranslation();

  return (
    <>
      <Navbar
        expand="lg"
        bg="dark"
        variant="dark"
        className="px-4 px-lg-5 py-3 py-lg-0"
      >
        <Container>
          <Navbar.Brand href="../" className="p-0">
            <img src="../assets/img/logo.png" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link onClick={() => scrollToSection("home")}>
                {t("home")}
              </Nav.Link>

              <Nav.Link onClick={() => scrollToSection("about")}>
                {t("about")}
              </Nav.Link>
              <Nav.Link href="menu"> {t("menu")}</Nav.Link>
              <Nav.Link onClick={() => scrollToSection("contact")}>
                {t("contact")}
              </Nav.Link>
              <LanguageSwitcher />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Box component="main">
        <Outlet />
        <FooterSection />
      </Box>
    </>
  );
}

export default ResponsiveDrawer;
