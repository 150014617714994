import WelcomeSection from "../components/HomeComponents/WelcomeSectionComponent";
import WhyUsSection from "../components/HomeComponents/WhyUsComponent";
import AboutSection from "../components/HomeComponents/AboutComponent";
import MissionSection from "../components/HomeComponents/MissionComponent";
import { Helmet } from "react-helmet-async";
import FindUsSection from "../components/HomeComponents/FindUsComponent";

function HomePage() {
  return (
    <>
      <Helmet>
        <title>Um Khater</title>
      </Helmet>
      <WelcomeSection />
      <AboutSection />
      <WhyUsSection />
      <MissionSection />
      {/* <MenuSection /> */}
      <FindUsSection />
      {/* <TestimonialsSection /> */}
    </>
  );
}

export default HomePage;
